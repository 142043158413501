import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";
const payments_endpoint = `payments/`;

export const ServerInterface_createStripeCheckout = async ({ data }) => {
  const payload = data;
  let createStripeCheckout = null;
  try {
    const StripeCheckoutURL = await slidAxios.post(`${payments_endpoint}create-checkout-session/`, payload);
    createStripeCheckout = StripeCheckoutURL.data.url;
  } catch (error) {
    console.error(error);
    if (!createStripeCheckout) createStripeCheckout = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      createStripeCheckout.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          createStripeCheckout.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          switch (error.response.data.message) {
            case "Missing pricing_id":
              createStripeCheckout.error_message = "STRIPE_MISSING_PRICING_ID";
              break;
            case "Missing is_recurring":
              createStripeCheckout.error_message = "STRIPE_MISSING_IS_RECURRING";
              break;
            default:
              createStripeCheckout.error_message = "STRIPE_CREATE_CHECKOUT_ERROR";
              break;
          }
          break;
        case 403:
          createStripeCheckout.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          createStripeCheckout.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      createStripeCheckout.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      createStripeCheckout.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return createStripeCheckout;
  }
};

export const ServerInterface_createPayment = async ({ data }) => {
  const payload = data;
  let createPaymentData = null;
  try {
    const createPaymentResponse = await slidAxios.post(payments_endpoint, payload);
    createPaymentData = createPaymentResponse.data;
  } catch (error) {
    if (!createPaymentData) createPaymentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      createPaymentData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          createPaymentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // "Missing field | "Invalid Data Format to Save"
          createPaymentData.error_message = error.response.data.message;
          break;
        case 403:
          createPaymentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          createPaymentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      createPaymentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      createPaymentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return createPaymentData;
  }
};

export const ServerInterface_getPayment = async () => {
  let paymentData = null;

  try {
    const paymentGetResponse = await slidAxios.get(payments_endpoint);
    paymentData = paymentGetResponse.data;
    paymentData.payments = paymentData.payments.filter((payment) => !payment.is_pending);
  } catch (error) {
    console.error(error);
    if (!paymentData) paymentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      paymentData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          paymentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          // "No Payment Found"
          paymentData.error_message = "NO_PAYMENTS_FOUND";
          break;
        case 403:
          paymentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          paymentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      paymentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      paymentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return paymentData;
  }
};

export const ServerInterface_updatePayment = async ({ data }) => {
  /*
  data = {
    payment_key:"some_valid_key",
    end_date:"some_valid_date"
  }
  */
  const payload = data;
  let updatePaymentData = null;
  try {
    const updatePaymentResponse = await slidAxios.put(`${payments_endpoint}${data.payment_key}/`, payload);
    updatePaymentData = updatePaymentResponse.data;
  } catch (error) {
    if (!updatePaymentData) updatePaymentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      updatePaymentData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          updatePaymentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // "Provided 'payment_key' Not Exists"
          updatePaymentData.error_message = error.response.data.message;
          break;
        case 403:
          updatePaymentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          updatePaymentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      updatePaymentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      updatePaymentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return updatePaymentData;
  }
};

import React from "react";
import ModalPortal from "./ModalPortal";
import ImageFullScreen from "components/ImageFullScreenModal/ImageFullScreen";
import styled from "styled-components";

const ImageFullScreenModal = () => {
  return (
    <ModalPortal target={"body"}>
      <ModalLayer>
        <ImageFullScreen />
      </ModalLayer>
    </ModalPortal>
  );
};

const ModalLayer = styled.div<{ isMobile?: boolean }>`
  position: ${(props) => (props.isMobile ? "fixed" : "absolute")};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 13, 14, 0.8);
  backdrop-filter: blur(4px);
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default ImageFullScreenModal;

import { useCallback } from "react";
import { isUserAuthenticated } from "utils/auth/cognitoAuthToolkit";
import { useAppSelector } from "hooks";
import ChannelService from "config/channel-talk";
import env from "config/env";

interface ChannelTalkProfileType {
  name: string;
  email: string;
  payment: string;
  clip_cnt: number;
  created_time: string;
  has_signup: boolean;
  note_cnt: number;
  has_folder: boolean;
  folder_cnt?: number;
  mobileNumber?: string;
}

const CHANNEL_TALK = env.common_api_key.channel_talk;

export const useChannelTalk = () => {
  const { userData } = useAppSelector((state) => state.slidGlobal);
  const { documents } = useAppSelector((state) => state.vdocs);

  const getChildFolderCount = (data: any) => {
    let childFolderCount = 0;
    data.forEach((document: any) => {
      if (document.is_folder) {
        childFolderCount++;
        childFolderCount = childFolderCount + getChildFolderCount(document.documents);
      }
    });
    return childFolderCount;
  };

  const bootChannelTalk = async () => {
    let channelProfile = {} as ChannelTalkProfileType;
    const isAuthenticated = await isUserAuthenticated();

    if (ChannelService) {
      ChannelService.shutdown();
    }

    if (isAuthenticated && userData && !userData.error_message && documents) {
      const phoneNumber = JSON.parse(userData.more_info).phone_number;
      channelProfile.name = userData.username;
      channelProfile.email = userData.email;
      channelProfile.payment = userData.payment;
      channelProfile.clip_cnt = userData.clip_cnt;
      channelProfile.created_time = userData.created_time;
      channelProfile.has_signup = isAuthenticated;
      channelProfile.note_cnt = documents.length;
      channelProfile.has_folder = documents.some((document: any) => document.is_folder);
      channelProfile.folder_cnt = getChildFolderCount(documents);

      if (phoneNumber) {
        channelProfile.mobileNumber = phoneNumber;
      }
    }

    if (ChannelService) {
      ChannelService.boot({
        pluginKey: CHANNEL_TALK,
        profile: channelProfile,
        memberId: userData ? userData.user_key : null,
        customLauncherSelector: "#channel-talk-btn",
      });
    }
  };

  return { bootChannelTalk };
};

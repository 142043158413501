import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";

const dayPass_endpoint = `day-pass/`;

export const ServerInterface_getDayPass = async () => {
  let dayPassGetResponse = {};
  try {
    dayPassGetResponse = await slidAxios.get(dayPass_endpoint);
  } catch (error) {
    console.error(error);
    if (!dayPassGetResponse) dayPassGetResponse = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return {};
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          dayPassGetResponse.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          dayPassGetResponse.error_message = "METHOD_NOT_ALLOWED";
          break;
        default:
          dayPassGetResponse.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      dayPassGetResponse.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      dayPassGetResponse.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return dayPassGetResponse;
  }
};

/**
 * This function activates the day pass for the user.
 * PUT /day-pass endpoint is used to activate the remaining day pass.
 *
 * returns updated day pass data
 */
export const ServerInterface_activateDayPass = async () => {
  let dayPassPutResponse = {};
  try {
    dayPassPutResponse = await slidAxios.put(dayPass_endpoint, {});
  } catch (error) {
    console.error(error);
    if (!dayPassPutResponse) dayPassPutResponse = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return {};
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          dayPassPutResponse.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          dayPassPutResponse.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // User already has an active day pass
          dayPassPutResponse.error_message = "DAY_PASS_ALREADY_ACTIVE";
          break;
        case 404:
          // No active day passes found
          dayPassPutResponse.error_message = "NO_DAY_PASSES_FOUND";
          break;
        case 422:
          // Day pass is not associated with a payment
          dayPassPutResponse.error_message = "DAY_PASS_NOT_ASSOCIATED_WITH_PAYMENT";
          break;
        default:
          dayPassPutResponse.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      dayPassPutResponse.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      dayPassPutResponse.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return dayPassPutResponse;
  }
};

export const ServerInterface_updateDayPass = async ({ data }) => {
  /*
  data = {
    day_pass_key:"some_valid_key",
    expired_at:"some_valid_date",
    is_expired: boolean
  }
  */
  let dayPassPutResponse = {};
  const payload = data;
  let updateDayPassData = null;
  try {
    dayPassPutResponse = await slidAxios.put(`${dayPass_endpoint}${data.day_pass_key}/`, payload);
    updateDayPassData = dayPassPutResponse.data;
  } catch (error) {
    if (!updateDayPassData) updateDayPassData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      updateDayPassData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          updateDayPassData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // "Provided 'day_pass_key' Not Exists"
          updateDayPassData.error_message = error.response.data.message;
          break;
        case 403:
          updateDayPassData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          updateDayPassData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      updateDayPassData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      updateDayPassData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return updateDayPassData;
  }
};

import ExtensionAnnouncementBanner from "components/banner/ExtensionAnnouncementBanner";
import SavedTimeBanner from "components/banner/SavedTimeBanner";
import StudyChallengeSavedTimeBanner from "components/banner/StudyChallengeSavedTimeBanner";
import { useAppSelector } from "hooks";
import React, { useEffect, useState } from "react";
import { getDefaultSettingCookie } from "utils/cookies/cookies";

const VideoDocumentEditorBanner = ({ width }: { width: number }) => {
  const { userData, screenOrientation, userStudyChallengeData } = useAppSelector((state) => state.slidGlobal);
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const [showExtensionAnnouncementBanner, setShowExtensionAnnouncementBanner] = useState(false);

  useEffect(() => {
    const extensionAnnouncementBannerCookie = getDefaultSettingCookie({ property: `showExtensionAnnouncementBanner` });

    if (extensionAnnouncementBannerCookie !== false) {
      setShowExtensionAnnouncementBanner(true);
    }
  }, []);

  if (showExtensionAnnouncementBanner && applicationType === "extension") {
    return <ExtensionAnnouncementBanner width={width || 0} />;
  }

  return userData?.payment === "trial" && screenOrientation === "horizontal" && (userStudyChallengeData?.isActive ? <StudyChallengeSavedTimeBanner /> : <SavedTimeBanner width={width || 0} />);
};

export default VideoDocumentEditorBanner;

import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useModalStore } from "store/useModalStore";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  closable?: boolean;
  target?: string;
  callback?: () => void;
}

const BaseModalContainer = ({ children, closable, callback }: Props) => {
  const { hideModal } = useModalStore();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Wrapper>
      <DimContainer
        closableDim={closable}
        onClick={() => {
          if (closable && callback) {
            callback();
            hideModal();
          }
        }}
        variants={fadeInOut}
        initial="initial"
        animate={"animate"}
        exit="exit"
      >
        {children}
      </DimContainer>
    </Wrapper>
  );
};

interface dimTypes {
  closableDim?: boolean | undefined;
}

const Wrapper = styled(motion.div)<dimTypes>`
  z-index: 9999998;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const DimContainer = styled(motion.div)<dimTypes>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--gray50_opacity);
  cursor: ${({ closableDim }) => (closableDim === false ? "not-allowed" : "default")};
`;

const fadeInOut = {
  initial: {
    opacity: 0,
  },

  animate: {
    opacity: 1,

    transition: {
      duration: 0.25,
      ease: "easeInOut",
    },
  },

  exit: {
    opacity: 0,

    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export default BaseModalContainer;

import React, { useEffect, useRef, useState } from "react";
import { useUpdateEffect } from "utils/customHook/customHook";
import useEditorStore from "store/useEditorStore";
import { debounce, throttle } from "lodash";
import USER_PRIVILEGES, { SlidFeatures } from "utils/privilegeManager";
import { createDocument, createDocumentHistory, updateDocument } from "redux/actions/vdocsActions";
import { RootState } from "redux/store";
import { useAppDispatch, useAppSelector } from "hooks";
import { EditorState } from "lexical";
import env from "config/env";
import isEqual from "lodash.isequal";
import { useHistory } from "react-router-dom";

export const useNote = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [documentUpdateErrorCount, setDocumentUpdateErrorCount] = useState(0);
  const currentDocument = useAppSelector((state: RootState) => state.vdocs.currentDocument);
  const { editorDocumentTitle, isFetchingDocument, currentDocumentKey } = useEditorStore();
  const editorStateRef = useRef<EditorState | null>(null);
  const currentDocumentRef = useRef<any>(null);
  const historyCreationErrorCountRef = useRef(0);
  const isUserPrivilegedToEditNoteRef = useRef(true);
  const noteTypeRef = useRef(currentDocumentRef.current?.mapped_videos?.length > 0 ? "vdocs" : "plain docs");
  const documentTitleRef = useRef(editorDocumentTitle);

  useEffect(() => {
    documentTitleRef.current = editorDocumentTitle;
  }, [editorDocumentTitle]);

  useEffect(() => {
    currentDocumentRef.current = currentDocument;
  }, [currentDocument]);

  useUpdateEffect(() => {
    if (documentUpdateErrorCount >= 3) {
      (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: "ALERT_AUTO_SAVE_ERROR" }));
    }
  }, [documentUpdateErrorCount]);

  const debouncedSaveDocument = useRef(debounce((fetchCurrentDocument = true) => saveDocument(fetchCurrentDocument), 500)).current;
  const debouncedTryCreateDocumentHistory = useRef(debounce(() => tryHistoryCreation(), 1000 * 10)).current;
  const throttleTryCreateDocumentHistory = useRef(throttle(() => createHistory(), 1000 * 10 * 60)).current;

  const saveDocument = async (fetchCurrentDocument = true) => {
    if (!isUserPrivilegedToEditNoteRef.current) return;
    const updatedContents = await editorStateRef.current?.toJSON();
    if (updatedContents?.root?.children?.length && updatedContents.root.children.length > env.maximum_supported_block_count) {
      // TODO: add modal to show maximum note size reached
      return;
    }
    if (currentDocumentRef.current && currentDocumentRef.current.document_key !== "new") {
      const updatedDocumentResponse: any = await dispatch(
        updateDocument({
          documentKey: currentDocumentRef.current["document_key"],
          title: documentTitleRef.current,
          content: updatedContents,
          fetchCurrentDocument,
          showError: false,
          origin: noteTypeRef.current,
        })
      );

      /**
       * Show Auto Saving Error Alert if auto save failure three times.
       */
      if (updatedDocumentResponse?.error_message) {
        if (updatedDocumentResponse?.error_message !== "INSUFFICIENT_PRIVILEGES") setDocumentUpdateErrorCount(documentUpdateErrorCount + 1);
        return;
      }
    } else {
      // create new note
      const createDocumentResponse: any = await dispatch(
        createDocument({
          title: documentTitleRef.current,
          content: updatedContents || {},
          origin: noteTypeRef.current,
        })
      );

      if (createDocumentResponse?.document_key) history.replace(`./${createDocumentResponse?.document_key}`);
    }

    setDocumentUpdateErrorCount(0);
  };

  const onChange = (editorState: EditorState) => {
    if (!isEqual(editorStateRef.current, editorState)) {
      debouncedSaveDocument(false);
      debouncedTryCreateDocumentHistory();
      editorStateRef.current = editorState;
    }
  };

  const tryHistoryCreation = () => {
    if (!currentDocumentRef) return;
    throttleTryCreateDocumentHistory();
  };

  const createHistory = async () => {
    if (!USER_PRIVILEGES.has(SlidFeatures.makeNoteHistory) || !currentDocumentRef.current) return;
    const historyCreationResponse: any = await dispatch(
      createDocumentHistory({
        documentKey: currentDocumentRef.current["document_key"],
        showError: historyCreationErrorCountRef.current !== 0,
        origin: noteTypeRef.current,
      })
    );

    /**
     * Send History Creation Sentry Error if history creation failure two times.
     */
    if (!historyCreationResponse) {
      historyCreationErrorCountRef.current += 1;
      return;
    }

    historyCreationErrorCountRef.current = 0;
  };

  return {
    saveDocument,
    debouncedSaveDocument,
    debouncedTryCreateDocumentHistory,
    onChange,
    tryHistoryCreation,
    createHistory,
  };
};

//auth 2.0 need discussion
import React, { memo } from "react";
import { Button, useModal } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { trackEvent } from "utils/eventTracking";
import { showCustomModal } from "utils/modal";
import SignupModal from "components/DemoVideoNoteEditor/SignupModal";
import { redirectToMyNotes } from "utils/utils";

interface MyNoteButtonProps {
  buttonLocation?: string;
}

const MyNotesButton = memo(({ buttonLocation }: MyNoteButtonProps) => {
  const { t } = useTranslation(["VideoNote"]);
  const { showModal, closeModal } = useModal();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { iframePort } = useAppSelector((state) => state.vdocs);

  return (
    <ButtonWrapper>
      <Button
        size={`small`}
        text={t("MyNotes", { ns: "VideoNote" })}
        callback={() => {
          if (buttonLocation === "demo page") {
            showCustomModal({ showModal, closeModal, history, customComponentContent: <SignupModal /> });
            trackEvent({ eventType: "click MY NOTES in demo" });
            return;
          }

          trackEvent({
            eventType: `Click my notes`,
            eventProperties: {
              page: `${buttonLocation}`,
            },
          });

          redirectToMyNotes({ history, iframePort });
        }}
        appearance={`line`}
      />
    </ButtonWrapper>
  );
});

const ButtonWrapper = styled.div`
  display: flex;
`;
export default MyNotesButton;

import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { createDocument, setClipRecordingCountdownNumber, setIsRecordActive, setLoaderBlockKeys, setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";
import { setIsGettingClipRecordingMediaPermission } from "redux/actions/vdocsActions";
import { setIsGettingExtensionMediaPermission } from "redux/actions/vdocsActions";
import { trackEvent } from "utils/eventTracking";
import urls from "config/disable_clip_recording_urls.json";
import Sweetalert from "sweetalert2";
import { isPreviousBlockEmpty } from "utils/editor/util";
import { v4 as uuidv4 } from "uuid";
import { recordClip, stopRecordClip } from "utils/record/recordClip";
import { sendAmplitudeData } from "utils/amplitude";
import { eventTypes } from "types/eventTracking";

const CLIP_RECORDING_MAX_SECONDS = 60;

export const useVideoRecord = () => {
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const { isExtensionMediaPermitted, currentVideo, isRecordActive, clipRecordingCountdownNumber, editorInstance, currentDocument, editorLastActiveBlockPosition, loaderBlockKeys } = useAppSelector(
    (state) => state.vdocs
  );
  const dispatch = useAppDispatch();
  const countdownIdRef = useRef<NodeJS.Timeout | null>(null);
  const isEditorEmpty = () => {
    return (
      editorInstance.blocks.getBlocksCount() === 0 ||
      (editorInstance.blocks.getBlocksCount() === 1 && editorInstance.blocks.getBlockByIndex(0)?.name === "paragraph" && editorInstance.blocks.getBlockByIndex(0)?.isEmpty) ||
      (editorInstance.blocks.getBlocksCount() === 1 && editorInstance.blocks.getBlockByIndex(0)?.name === "fake")
    );
  };

  const insertVideoLoader = async () => {
    const { getCurrentBlockIndex, insert } = editorInstance.blocks;
    // check current document
    if (!currentDocument) {
      await dispatch(createDocument({ origin: "vdocs" }));
    }

    /**
     * editorLastActiveBlockPosition is updated in EditorComponent.tsx for store last index of the block when user blurs the editor.
     * When document key is changed by clicking other note in the note list, editorLastActiveBlockPosition becomes undefined so that new video block is inserted at the end of the document.
     */
    let videoLoaderInsertIndex;
    if (editorLastActiveBlockPosition !== undefined || editorLastActiveBlockPosition === null) {
      videoLoaderInsertIndex = editorInstance.blocks.getCurrentBlockIndex() === -1 ? editorLastActiveBlockPosition + 1 : undefined;
    } else {
      editorInstance.caret.setToLastBlock("end", 0);
    }
    const loaderBlockKey = uuidv4();

    dispatch(setLoaderBlockKeys([...loaderBlockKeys, loaderBlockKey]));
    insert(
      "videoLoader",
      {
        blockKey: loaderBlockKey,
        clipRecordingMaxCountdownNumber: CLIP_RECORDING_MAX_SECONDS,
      },
      undefined,
      videoLoaderInsertIndex,
      true
    );
    const previousBlockIndex = getCurrentBlockIndex() - 1;
    if (isPreviousBlockEmpty(previousBlockIndex)) {
      try {
        editorInstance.blocks.delete(previousBlockIndex);
      } catch (e) {
        // when focus is lost,
        // Uncaught (in promise) TypeError: Cannot read property 'lastInput' of undefined
        // will happen.
        // ignore this for now. it's editor js error.
        // https://github.com/codex-team/editor.js/pull/1218
      }
    }
    insert();
    editorInstance.caret.setToBlock(getCurrentBlockIndex());
    if (editorInstance.blocks.getBlockByIndex(getCurrentBlockIndex() - 1)) {
      editorInstance.blocks.getBlockByIndex(getCurrentBlockIndex() - 1).holder.scrollIntoView();
    }
  };

  const startCountdown = () => {
    if (clipRecordingCountdownNumber <= 0) {
      clipRecordStop();
      return;
    }
    dispatch(setClipRecordingCountdownNumber(clipRecordingCountdownNumber - 1));
  };

  const clipRecordStart = () => {
    trackEvent({
      eventType: "Check VIDEO SNIPPET PERMISSION in video note page",
      eventProperties: {
        is_permission_needed: !isExtensionMediaPermitted,
      },
    });

    if (!isExtensionMediaPermitted) {
      dispatch(setIsGettingExtensionMediaPermission(true));
      dispatch(setIsGettingClipRecordingMediaPermission(true));
      dispatch(setShowExtensionMediaPermissionModal(true));
      return;
    }

    let shouldBeDisabled = false;
    urls["website_urls"].forEach((disallowedUrl) => {
      if (currentVideo?.originUrl.includes(disallowedUrl)) shouldBeDisabled = true;
    });
    if (shouldBeDisabled) {
      Sweetalert.fire({
        target: `.video-document-container`,
        heightAuto: false,
        customClass: {
          container: "position-absolute",
        },
        title: lang === "ko" ? "클립 녹화 이용불가!" : "Not available!",
        html:
          lang === "ko"
            ? `
                해당 사이트 내에서는 클립 녹화를 할 수 없습니다.
            `
            : `
                Video Snippet is not available in this website.
            `,
        icon: "warning",
        confirmButtonText: lang === "ko" ? "닫기" : "Okay",
      });
      return;
    }
    if (isRecordActive) return;
    dispatch(setIsRecordActive(true));
    countdownIdRef.current = setInterval(() => {
      startCountdown();
    }, 1000);
    insertVideoLoader();
    recordClip({
      applicationType,
    });
    sendAmplitudeData(`SLID_2_START_CLIP_RECORDING`);
    trackEvent({
      eventType: eventTypes.success.CLIP_RECORDING_START,
    });
  };

  const clipRecordStop = async () => {
    dispatch(setIsRecordActive(false));
    clearInterval(countdownIdRef.current as NodeJS.Timeout);
    dispatch(setClipRecordingCountdownNumber(CLIP_RECORDING_MAX_SECONDS));
    // Replace videoLoad with loader when loading video
    const savedData = await editorInstance.save();
    const loaderBlockKey = loaderBlockKeys.shift();
    savedData.blocks.forEach((block, index) => {
      if (block.type !== "videoLoader") return;
      if (block.data.blockKey === loaderBlockKey) {
        editorInstance.blocks.delete(index);
        editorInstance.blocks.insert(
          "loader",
          {
            blockKey: loaderBlockKey,
          },
          undefined,
          index,
          true
        );
      }
    });
    // push loaderBlockKey again to let video block to know where to insert.
    dispatch(setLoaderBlockKeys([...loaderBlockKeys, loaderBlockKey]));
    stopRecordClip({
      applicationType,
    });
    trackEvent({
      eventType: "Click clip recording end",
    });
  };

  return {
    clipRecordStart,
    clipRecordStop,
  };
};
